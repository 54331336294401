import React from 'react';
import Magnet from '../../../components/svgs/magnet';
import { Link } from "gatsby";
import {graphql, useStaticQuery} from 'gatsby';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {colors} from '../../../style/constants';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingBottom: 0,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 60,
    },
  },
  links: {
    paddingBottom: 120,
    borderLeft: '1px #00ddae solid',
    position: 'relative',
    '& ul': {
      marginTop: 10,
      marginLeft: 40,
      '& li': {
        lineHeight: 1.5,
      },
      '& li:first-child': {
        fontWeight: 500,
        marginBottom: 10,
        color: colors.DARK_GREY,
      },
    },
    '& svg': {
      width: 40,
      position: 'absolute',
      top: 0,
      left: -20,
      [theme.breakpoints.down('md')]: {
        position: 'relative',
      }
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 0,
      '& ul': {
        marginTop: -25,
        marginBottom: 55
      }
    }
  },
}));

const Links = (props) => {
  const data = useStaticQuery(graphql`
    query industriesFooterLinks {
      allContentfulSecteurs(sort: {fields: createdAt}) {
        edges {
          node {
            name
            permalink
            node_locale
          }
        }
      }
    }
  `)

  const classes = useStyles();

  const renderIndustries = () => {
    if (props.lang === 'fr-CA') {
      return data.allContentfulSecteurs.edges.map((item, index) => {
        return item.node.node_locale === 'fr-CA' ? <li key={index}><Link to={item.node.permalink}>{item.node.name}</Link></li>
        : ""
      });
    }
    if (props.lang === 'en-CA') {
      return data.allContentfulSecteurs.edges.map((item, index) => {
        return item.node.node_locale === 'en-CA' ?
          <li key={index}><Link to={item.node.permalink}>{item.node.name}</Link></li>
        : "" 
      });
    }
  };

  const renderProductsLinks = () => {
    return (
      <>
        <li>{props.lang === 'en-CA' ? 'PRODUCTS' : 'PRODUITS'}</li>
        <li>{props.lang === 'en-CA' ? <Link to="/en/software">Software</Link> : <Link to="/logiciel">Logiciel</Link>}</li>
        <li>{props.lang === 'en-CA' ? <Link to="/en/content">Content</Link> : <Link to="/contenu">Contenu</Link>}</li>
        <li>{props.lang === 'en-CA' ? <Link to="/en/pricing">Pricing</Link> : <Link to="/tarifs">Tarifs</Link>}</li>
        <li>{props.lang === 'en-CA' ? <Link to="/en/hardware">Hardware</Link> : <Link to="/equipement">Équipement</Link>}</li>
        <li>{props.lang === 'en-CA' ? <Link to="/en/queue-management">Queue management</Link> : <Link to="/gestion-de-file-dattente">Gestion de file d'attente</Link>}</li>
        <li>{props.lang === 'en-CA' ? <Link to="/en/touch-screens">Touch screens</Link> : <Link to="/bornes-tactiles">Bornes tactiles</Link>}</li>
      </>
    );
  } 

  const renderBusinessLinks = () => {
    return (
      <>
        <li>{props.lang === 'en-CA' ? 'BUSINESS' : 'ENTREPRISE'}</li>
        <li>{props.lang === 'en-CA' ? <Link to="/en/blog">Blog</Link> : <Link to="/blogue">Blogue</Link>}</li>
        <li>{props.lang === 'en-CA' ? <Link to="/en/industries">Industries</Link> : <Link to="/secteurs">Secteurs</Link>}</li>
        <li>{props.lang === 'en-CA' ? <Link to="/en/contact-us">Contact us</Link> : <Link to="/nous-parler">Nous parler</Link>}</li>
      </>
    );
  } 

  return (
  <section className={classes.wrapper}>
    <Container>
      <Grid container>
        <Grid item xs={false} lg={6}></Grid>
        <Grid className={classes.links} item container xs={12} lg={6}>
          <Grid item>
            <Magnet />
          </Grid>
          <Grid item xs={12} sm="auto">
          <ul>
            {renderProductsLinks()}
          </ul>
          </Grid>
          <Grid item xs={12} sm="auto">
          <ul>
            <li>SECTEURS</li>
            {renderIndustries()}
          </ul>
          </Grid>
          <Grid item xs={12} sm="auto">
          <ul>
            {renderBusinessLinks()}
          </ul>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  </section>
  )
}

export default Links;