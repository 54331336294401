/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Footer from "./Footer/";
import Header from "./Header/";
import "../style/reset.css";
import "../style/App.css";
import { Helmet } from "react-helmet";
import HardwareSection from './hardware';
import CookieConsent from "react-cookie-consent";
import { colors } from "../style/constants";
import { Link } from "gatsby";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  cookiesConsent: {
    background: colors.WHITE,
    position: "fixed", 
    top: "25vh", 
    height: "50vh",
    width: "60vw",
    left: "20vw", 
    zIndex: 999, 
    border: "solid 2px " + colors.TURQUOISE, 
    borderRadius: 25,
    boxShadow: "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
    "& .CookieConsent": {
      padding: 40,
      height: "100%",
      display: "flex",
      flexFlow: "column",
      justifyContent: "space-between",
      "& p, ul": {
        fontSize: "1.25em!important",
        marginTop: 15,
        color: colors.GREY,
      },
      "& ul": {
        listStyle: "circle",
        paddingLeft: 20,
        marginTop: 10,
      },
      "& > :first-child": {
        overflowY: "scroll",
      },
      "& > :last-child": {
        marginTop: 20,
        display: "flex",
        width: "100%",
        justifyContent: "space-between"
      }
    },
    [theme.breakpoints.down('xs')]: {
      height: "60vh",
      width: "80vw",
      top: "20vh",
      left: "10vw",
      "& .CookieConsent": {
        padding: "30px 20px",
        "& button": {
          padding: "0 5px"
        }
      }
    }
  }
}));

const Layout = (props) => {
  const [cookieConsentIsShown, showCookieConsent] = useState("");

  useEffect(() => {
    const cookieName = "gatsby-gdpr-google-analytics,gatsby-gdpr-google-tagmanager";
    const cookieValue = getCookie(cookieName);
    cookieValue ? showCookieConsent(false) : showCookieConsent(true);
  }, []);

  const getCookie = (name) => {
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookies = decodedCookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return "";
  };

  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <>
      <Helmet>
        <meta name="google-site-verification" content="J8bjMAaS6oxhlun7FPu0sHqStlGp_hUgh6eQcgBXAZQ" />
      </Helmet>
      <Header 
      lang={props.lang} 
      slider={props.slider} 
      title={props.title} 
      subTitle={props.subTitle} 
      siteTitle={data.site.siteMetadata.title} 
      headerImage={props.headerImage} 
      headerImageAlt={props.headerImageAlt} 
      anchorLink={props.anchorLink} 
      anchorLinkText={props.anchorLinkText}
      anchorLink2={props.anchorLink2} 
      anchorLinkText2={props.anchorLinkText2}
      icon={props.icon}
      switchLink={props.switchLink} 
      />
        <main>
          {props.children}
          <HardwareSection lang={props.lang} />
        </main>
      <Footer lang={props.lang} />
      {cookieConsentIsShown ?
      <div className={classes.cookiesConsent}>
        <CookieConsent
          onAccept={(() => showCookieConsent(false))}
          onDecline={(() => showCookieConsent(false))}
          disableStyles
          style={{position: "static", alignItems: "flex-end", flexFlow: "column", boxSizing: "border-box", maxHeight: "80vh"}}
          contentStyle={{margin: 0, marginBottom: 10, fontSize: "0.875em", lineHeight: 1.25, flex: 1}}
          buttonText={props.lang === "en-CA" ? "I Accept" : "J'accepte"}
          enableDeclineButton
          containerClasses="CookieConsent"
          buttonClasses="clickable"
          declineButtonText={props.lang === "en-CA" ? "continue without cookie" : "continuer sans cookie"}
          cookieName={['gatsby-gdpr-google-analytics', 'gatsby-gdpr-google-tagmanager']}
          buttonStyle={{ cursor: "pointer", flex: "0 0 auto", background: colors.TURQUOISE, color: colors.ATT_BLUE, fontSize: "0.875em", borderRadius: 25, margin: 0, fontFamily: 'Maven Pro', fontWeight: 600, marginLeft: 15, padding: "10px 20px", 	boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)" }}
          declineButtonStyle={{ background: "none", color: colors.TURQUOISE, fontSize: "0.875em", fontFamily: 'Maven Pro', fontWeight: 400, margin: 0, padding: "10px 0" }}
        >
          {props.lang === "en-CA" ? 
          <>
            <h4>Your privacy is important to us</h4>
            <p>We use cookies to enhance your experience on our website. Cookies are small text files placed on your device that help us provide a more personalized and efficient user experience.</p>
            <p>By accepting the use of cookies, you will benefit from the following advantages:</p>
            <ul>
              <li>Customization of the user experience</li>
              <li>Website analysis enabling optimization of the site</li>
              <li>Personalized advertising and content</li>
            </ul>
            <p>By clicking "I Accept," you consent to the use of all cookies on our website. You can also manage your cookie preferences by accessing your browser settings.</p>
            <p>To learn more, please see our <Link style={{color: colors.TURQUOISE}} to="/en/privacy-policy">Privacy Policy</Link></p>
          </> : 
          <>
            <h4>Le respect de votre vie privée est important pour nous</h4>
            <p>Nous utilisons des cookies pour améliorer votre expérience sur notre site web. Les cookies sont de petits fichiers texte placés sur votre appareil qui nous aident à fournir une expérience utilisateur plus personnalisée et efficace.</p>
            <p>En acceptant l'utilisation des cookies, vous bénéficierez des avantages suivants : </p>
            <ul>
              <li>Personnalisation de l'expérience utilisateur</li>
              <li>Analyse du site web permettant l'optimisation de ce dernier</li>
              <li>Publicité et contenu personnalisé</li>
            </ul>
            <p>En cliquant sur «J'accepte», vous consentez à l'utilisation de tous les cookies sur notre site web. Vous pouvez également gérer vos préférences en matière de cookies en accédant aux paramètres de votre navigateur.</p>
            <p>Pour en savoir plus, veuillez consulter notre <Link style={{color: colors.TURQUOISE}} to="/politique-de-confidentialite">Politique de confidentialité</Link></p>
            </>}
        </CookieConsent>
      </div> : ""}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout