import React from 'react';
import Support from './Support';
import Bar from './Bar';
import Links from './Links';
import Awards from './awards';
import {graphql, useStaticQuery} from 'gatsby';

const Footer = (props) => {
  const data = useStaticQuery(graphql`
    query AwardsList {
      allContentfulListeDeRecompenses(limit: 2, sort: {fields: createdAt, order: DESC}) {
        edges {
          node {
            list {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)

  return (
    <footer>
      <Support lang={props.lang}/>
      <Awards lang={props.lang} awards={data.allContentfulListeDeRecompenses}/>
      <Links lang={props.lang}/>
      <Bar lang={props.lang}/>
    </footer>
  );
}

export default Footer;