import React, { useState } from 'react';
import { Link } from "gatsby";
import {colors, sizes} from '../../../style/constants.js';
import Fb from '../../svgs/fb.js';
import Ig from '../../svgs/ig.js';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    background: colors.LIGHT_GREY,
    color: colors.GREY,
    padding: '10px 0',
    '& a': {
      marginLeft: 30,
    },
  },
  logos: {
    '& svg': {
      height: sizes.LARGE,
      width: 'auto',
      [theme.breakpoints.down('xs')]: {
        height: sizes.MEDIUM,
      }
    }
  },
  links: {
    '& > :first-child': {
      marginTop: 2
    },
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column-reverse',
      '& > :first-child': {
        marginTop: 0
      },
      '& > :last-child': {
        marginBottom: 5,
        '& a': {
          marginLeft: 0,
        },
      }
    }
  }
}));

const Bar = (props) => {

  const [state, setState] = useState({
    fbHover: false,
    igHover: false
  });

  const toggleFbHover = () => {
    setState({fbHover: !state.fbHover});
  }  

  const toggleIgHover = () => {
    setState({igHover: !state.igHover});
  }  

  const classes = useStyles();

  return (
    <section className={classes.wrapper}>
      <Container>
        <Grid container alignItems='center' justifyContent='space-between'>
          <Grid item container xs={8} sm={9} md={10} className={classes.links} justifyContent="flex-start">
            <Grid item xs={12} sm='auto'><h6>© ATTRACTIF, 2020. {props.lang === 'en-CA' ? 'all rights reserved' : 'tous droits réservés'}.</h6></Grid>
            <Grid item xs={12} sm='auto'>{props.lang === 'en-CA' ? <Link to="/en/privacy-policy">Privacy policy</Link> : <Link to="/politique-de-confidentialite">Politique de confidentialité</Link>}</Grid>
          </Grid>
          <Grid item container justifyContent='flex-end' xs={4} sm={3} md={2}>
            <a className={classes.logos} href="https://www.facebook.com/attractifinc/" target="_blank" rel="noopener noreferrer" onMouseEnter={toggleFbHover} onMouseLeave={toggleFbHover}><Fb aria-label="Facebook" iconColor={state.fbHover ? '#1877F1' : '#999'} /></a>
            <a className={classes.logos} href="https://www.instagram.com/attractif_affichage_dynamique/" target="_blank" rel="noopener noreferrer" onMouseEnter={toggleIgHover} onMouseLeave={toggleIgHover}><Ig aria-label="Instagram"  iconColor={state.igHover ? 'url(#igGradient)' : '#999'} /></a>
          </Grid>
        </Grid>
      </Container>
    </section>
  )
}

export default Bar;