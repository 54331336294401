import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image"
import AnchorLink from 'react-anchor-link-smooth-scroll';
import InnerLinkButton from '../../svgs/buttons/InnerLinkButton';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {colors} from '../../../style/constants.js';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: colors.ATT_BLUE,
    height: 'calc(100vh - 240px)',
    clipPath: 'polygon(0 0, 100% 0, 100% calc(100% - 60px), 0% 100%)',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 120px)',
      [theme.breakpoints.down('xs')]: {
        height: '100vh',
        clipPath: 'polygon(0 0, 100% 0, 100% calc(100% - 30px), 0% 100%)',
      }
    },
    position: 'relative',
    color: colors.WHITE,
    padding: 0,
    '& > *': {
      height: '100%',
    },
    '& .gatsby-image-wrapper': {
      position: 'absolute!important',
      top: 0,
      left: 0,
      width: '100%',
      zIndex: -2,
    },
    '& h1, p': {
      maxWidth: '80%',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
    '& h1': {
      marginBottom: 30,
    },
    '& p': {
      color: colors.WHITE,
    },
    '& button': {
      marginTop: 30,
      marginRight: 30,
      '& svg': {
        transform: 'rotate(90deg)'
      }
    }
  },
  overlay: {
    background: 'linear-gradient(to right, rgba(0,27,46,1) 0%,rgba(0,27,46,0.95) 40%,rgba(0,27,46,0) 100%)',
    position: 'absolute',
    width: '100%',
    zIndex: -1,
  }
}));

const Slider = (props) => {
  const classes = useStyles();
  return (
  <section className={classes.wrapper + ' header-slider'}>
    <div className={classes.overlay}/>
    <GatsbyImage
      image={props.headerImage.gatsbyImageData}
      alt={props.headerImageAlt ? props.headerImageAlt : ''}
      imgStyle={{objectPosition: 'center right'}}
    />
    <Grid container direction='column' justifyContent='center'>
      <Container>
        <h1>{props.title}</h1>
        <p>{props.subTitle}</p>
        <Grid container>
        {props.anchorLink ? <AnchorLink offset='160' href={props.anchorLink}><InnerLinkButton text={props.anchorLinkText ? props.anchorLinkText : "En savoir plus"} /></AnchorLink> : ""}
        {props.anchorLink2 ? <AnchorLink offset='160' href={props.anchorLink2}><InnerLinkButton text={props.anchorLinkText2 ? props.anchorLinkText2 : "En savoir plus"} /></AnchorLink> : ""}
        </Grid>
      </Container>
    </Grid>
  </section>
  )
}

export default Slider;