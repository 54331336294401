import React, {useState} from 'react';
import Nav from './Nav';
import Slider from './Slider';
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import {colors} from '../../style/constants';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  noSlider: {
    background: colors.ATT_BLUE,
    paddingTop: 196,
    paddingBottom: 90,
    clipPath: 'polygon(0 0, 100% 0, 100% calc(100% - 60px), 0% 100%)',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 150,
      clipPath: 'polygon(0 0, 100% 0, 100% calc(100% - 30px), 0% 100%)',
    },
    '& h1': {
      color: colors.WHITE,
      textAlign: 'center',
      '& span': {
        display: 'block'
      }
    },
    '& svg': {  
      width: 45,
      height: 'auto',
      filter: 'brightness(0) invert(1)',
      [theme.breakpoints.down('sm')]: {
        width: 35,
        [theme.breakpoints.down('xs')]: {
          width: 30,
        },
      },
    },
  },
  icon: {
    width: 90,
    height: 90,
    marginRight: 30,
    marginLeft: -90,
    background: colors.TURQUOISE,
    borderRadius: '50%',
    boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.25)',
    [theme.breakpoints.down('sm')]: {
      marginLeft: -75,
      width: 75,
      height: 75,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
  }
}));

const Header = (props) => {
  const [navBarColor, setNavBarColor] = useState('rgba(0,27,46,0)');
  if (typeof window !== 'undefined') {
    window.onscroll = () => {
      if(window.scrollY <= 50) {
        setNavBarColor('rgba(0,27,46,0)');
      }
      else {
        setNavBarColor('rgba(0,27,46,1)');
      }
    };
  }
  
  const classes = useStyles();
  return (
    <header>
    <Nav switchLink={props.switchLink} lang={props.lang} navBarColor={navBarColor}/>
    {props.slider === true ? 
    <Slider 
    headerImage={props.headerImage} 
    headerImageAlt={props.headerImageAlt}
    title={props.title} 
    subTitle={props.subTitle} 
    anchorLink={props.anchorLink} 
    anchorLinkText={props.anchorLinkText}
    anchorLink2={props.anchorLink2} 
    anchorLinkText2={props.anchorLinkText2}
    icon={props.icon}
    /> 
    : <section className={classes.noSlider}>
        <Grid item container direction='row' alignItems='center' justifyContent='center' xs='auto'>
          {props.icon ? <Grid className={classes.icon} container justifyContent='center' alignItems='center'>{props.icon}</Grid> : ""}
          <Grid><h1><span>{props.subTitle}</span><strong>{props.title}</strong></h1></Grid>
        </Grid>
      </section>}
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
