import React from 'react';
import { Link } from "gatsby";
import InnerLinkButton from './svgs/buttons/InnerLinkButton';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {hardwareEn, hardwareFr} from '../data/home/hardware';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
    textAlign: 'center',
    '& h2': {
      marginBottom: 10
    },
    '& p': {
      marginBottom: 30
    }
  },
  hardwareList: {
    marginBottom: 30,
    display: 'flex',
    position: 'relative',
    flexWrap: 'wrap',
    '& svg': {
      marginBottom: 15,
    },
    '& > :nth-child(1), > :nth-child(3)': {
      borderRight: '0.5px #656a72 solid',
    },
    '& :before, :after': {
      display: 'block',
      width: 9.5,
      height: 9.5,
      position: 'absolute',
      border: '0.5px #656a72 solid',
      borderRadius: '50%',
      left: 0,
      right: 0,
      margin: '0 auto',
      content: '""',
    },
    '& :before': {
      top: -5,
    },
    '& :after': {
      top: 0,
      bottom: 0,
      margin: 'auto',
    }
  },
  hardware: {
    boxSizing: 'border-box',
    height: 200,
    borderTop: '0.5px #656a72 solid', 
    '& img:': {
      marginBottom: 30,
      width: 50,
      height: 'auto'
    },
    [theme.breakpoints.down('xs')]: {
      height: 160,
      '& > svg': {
        marginBottom: 10,
        width: 45,
        height: 45
      }
    },
  }
}));

const HardwareSection = (props) => {

  const classes = useStyles();

  const renderHardware = () => {
    if (props.lang === 'en-CA') {
      return hardwareEn.map((hardware, index) => {
        return (
          <Grid className={classes.hardware} alignItems='center' justifyContent='center' direction="column" container item xs={6} key={index}>
            <Link to={hardware.link}>
              {hardware.picto}
              <h4>{hardware.name}</h4>
            </Link>
          </Grid>
        );
      });
    }
    else {
      return hardwareFr.map((hardware, index) => {
        return (
          <Grid className={classes.hardware} alignItems='center' justifyContent='center' direction="column" container item xs={6} key={index}>
            <Link to={hardware.link}>
              {hardware.picto}
              <h4>{hardware.name}</h4>
            </Link>
          </Grid>
        );
      });
    }
  };

  return (
    <section className={classes.wrapper}>
      <Container>
        <Grid container justifyContent='center'>
          <h2>{props.lang === 'en-CA' ? 'Hardware for all your needs' : 'De l’équipement pour tous vos besoins'}</h2>
          <p>{props.lang === 'en-CA' ? 'Whether you already have your screens or are starting from scratch, it\'s easy for us!' : 'Que vous ayez déjà vos écrans ou que vous partiez de zéro, c\'est simple pour nous!'}</p>
          <Grid container className={classes.hardwareList}>
            {renderHardware()}
          </Grid>
          {props.lang === 'en-CA' ? <Link to="/en/hardware"><InnerLinkButton text="Hardware" /></Link> : <Link to="/equipement"><InnerLinkButton text="Équipement" /></Link>}
        </Grid>
      </Container>
    </section>
  );
}

export default HardwareSection;