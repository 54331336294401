import React from 'react';
import InnerLinkButton from '../../svgs/buttons/InnerLinkButton';
import ContactImg from "../../../img/home/home.png"
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {colors} from '../../../style/constants';
import { Link } from "gatsby";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundImage: 'url(' + ContactImg + ')',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '21.875vw',
    backgroundPosition: 'bottom right',
    backgroundColor: colors.ATT_BLUE,
    display: 'grid',
    overflow: 'hidden',
    position: 'relative',
    '& h2': {
      backgroundColor: colors.ATT_BLUE,
      marginBottom: 30,
      color: colors.WHITE, 
    },
  },
}));

const Support = (props) => {
  
  const classes = useStyles();

  return (
  <section className={classes.wrapper}>
    <Container>
      <Grid container justifyContent='center'>
        <Grid container alignItems='center'>
          <Grid className={classes.infos} item container justifyContent='center' alignItems='center' xs={12}>
              <h2>{props.lang === 'en-CA' ? 'Ready to get started?' : 'Prêt à vous propulser?'}</h2>
          </Grid>
        </Grid>
        <Link to={props.lang === 'en-CA' ? '/en/contact-us' : '/nous-parler'}><InnerLinkButton aria-label={props.lang === 'en-CA' ? 'Ask for a demo' : 'Demandez une démo'} text={props.lang === 'en-CA' ? 'Ask for a demo' : 'Demandez une démo'} /></Link>
      </Grid>
    </Container>
  </section>
  )
}

export default Support;