import React from 'react';

const Cpus = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60px" height="60px" viewBox="0 0 59 26.93">
      <path d="M59,4.06a1,1,0,0,0,0-.17.52.52,0,0,0-.06-.13.71.71,0,0,0-.1-.14l-.05-.06-.06,0a.51.51,0,0,0-.16-.09l-.13,0-.07,0L33.06,0h-.18L.72,3.36l-.08,0-.13,0a.56.56,0,0,0-.17.09l-.06,0a.21.21,0,0,0,0,.06.61.61,0,0,0-.12.15l-.06.11a1.29,1.29,0,0,0,0,.19s0,.06,0,.1V19.82a.79.79,0,0,0,.62.78l.29.07v.12a.83.83,0,0,0,.83.83H4.93L23.31,25.9v.2a.83.83,0,0,0,.83.83h3.13a.81.81,0,0,0,.81-.78l25.19-3.78a.82.82,0,0,0,.61.28H57a.83.83,0,0,0,.83-.83v-.14l.48-.07a.8.8,0,0,0,.68-.79V4.16S59,4.1,59,4.06ZM33,1.61,51.63,4.09,25.76,7,7.24,4.29ZM1.6,5.08,24.68,8.45V24.58L1.6,19.19ZM26.81,24.72V8.49L57.4,5.05V20.13Z" fill="#00ddae"/>
      <polygon points="53.45 9.98 32.19 12.71 32.19 20.08 53.45 16.99 53.45 9.98" fill="#00ddae"/>
    </svg>
  );
};

const Screens = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60px" height="60px" viewBox="0 0 60 47.79">
      <path d="M59.09,0H.91A.91.91,0,0,0,0,.91v46a.91.91,0,0,0,.91.91H59.09a.91.91,0,0,0,.91-.91V.91A.91.91,0,0,0,59.09,0Zm-.91,46H1.82V43H58.18ZM1.82,1.82H58.18v3H1.82Zm0,4.78H58.18V41.3H1.82Z" fill="#00ddae"/>
      <path d="M43.35,10.87h0a.56.56,0,0,0-.5-.14l-11.6,2.7a.55.55,0,0,0-.34.24.59.59,0,0,0-.06.41,10,10,0,0,0,.89,2.3l-5.16,5.16-4.31.22a.5.5,0,0,0-.35.16l-4.81,4.81a.53.53,0,0,0,.4.91h0l4.1-.22a7.77,7.77,0,0,0,1.66,2.76l-1.36,1.36a.54.54,0,0,0,0,.76.57.57,0,0,0,.76,0L24,30.94A8.21,8.21,0,0,0,26.8,32.6l-.22,4.1a.53.53,0,0,0,.31.52.54.54,0,0,0,.22,0,.55.55,0,0,0,.38-.15l4.81-4.82a.52.52,0,0,0,.16-.35l.22-4.3,1.3-1.3h.07v-.07l3.79-3.78a10.58,10.58,0,0,0,2.3.89.49.49,0,0,0,.4-.07.56.56,0,0,0,.25-.33l2.7-11.6A.54.54,0,0,0,43.35,10.87Zm-11.8,17.9-.15,2.91-3.68,3.68.16-2.92Zm-6.1-6.1-3.67,3.67-2.93.16,3.68-3.68Zm6.31-4.8.53-.53a15.32,15.32,0,0,0,4.11,4.27A7.62,7.62,0,0,1,31.76,17.87Zm-2,2L31,18.66a8.56,8.56,0,0,0,5.19,4l-.89.89a1.13,1.13,0,0,1-.87.07A7.54,7.54,0,0,1,29.75,19.88Zm-2,2L29,20.67a8.53,8.53,0,0,0,5.19,4l-.89.89a1.1,1.1,0,0,1-.87.08A7.58,7.58,0,0,1,27.74,21.89Zm.39,8.78-.93.94a6.89,6.89,0,0,1-2.42-1.42l.73-.73A6.89,6.89,0,0,0,28.13,30.67Zm-5.19-4a8.85,8.85,0,0,0,1.73,2.1l-.65.65A7,7,0,0,1,22.61,27Zm4.57.77a7.09,7.09,0,0,0,2.63,1.21l-.89.88c-.09.1-.38.17-.87.08a5.94,5.94,0,0,1-2.1-.94ZM27.43,26a7.51,7.51,0,0,1-1.69-2.14L27,22.67a8.89,8.89,0,0,0,1.73,2.11ZM25,24.68a8.8,8.8,0,0,0,1.72,2.11L25.43,28a7.33,7.33,0,0,1-1.7-2.13Zm6.3,2.87c-.09.09-.37.17-.86.08a5.86,5.86,0,0,1-2.1-.94l1.23-1.24a7,7,0,0,0,2.62,1.21l-.89.89ZM42.25,12,39.87,22.18A13,13,0,0,1,32,14.35Z" fill="#00ddae"/>
      <path d="M24.44,32.91h0a.55.55,0,0,0-.38.16L22.8,34.33a.54.54,0,0,0,.76.76l1.26-1.26a.54.54,0,0,0,.15-.38.52.52,0,0,0-.15-.38A.55.55,0,0,0,24.44,32.91Z" fill="#00ddae"/>
      <path d="M19.51,31.57a.54.54,0,0,0,.38-.15l1.26-1.26a.54.54,0,0,0,0-.76.55.55,0,0,0-.38-.16h0a.53.53,0,0,0-.38.16l-1.26,1.26a.53.53,0,0,0,0,.76A.54.54,0,0,0,19.51,31.57Z" fill="#00ddae"/>
      <path d="M21.15,33.07a.54.54,0,0,0-.76,0l-1.26,1.26a.54.54,0,0,0,.76.76l1.26-1.26A.54.54,0,0,0,21.15,33.07Z" fill="#00ddae"/>
      <path d="M21.31,35.82l-1.26,1.26a.53.53,0,0,0,0,.76.52.52,0,0,0,.38.15.51.51,0,0,0,.37-.15l1.26-1.26a.54.54,0,0,0,0-.76A.53.53,0,0,0,21.31,35.82Z" fill="#00ddae"/>
      <path d="M17.64,32.15l-1.26,1.26a.53.53,0,0,0,0,.76.52.52,0,0,0,.38.15.51.51,0,0,0,.37-.15l1.27-1.26a.56.56,0,0,0,0-.76A.54.54,0,0,0,17.64,32.15Z" fill="#00ddae"/>
    </svg>
  );
};

const Videowalls = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60px" height="60px" viewBox="0 0 60 47.7">
      <path d="M30,0H.46A.47.47,0,0,0,0,.46V23.8a.46.46,0,0,0,.46.46H30a.46.46,0,0,0,.46-.46V.46A.47.47,0,0,0,30,0Zm-.46,23.34H.92V21.83H29.54ZM.92.92H29.54V2.43H.92Zm0,2.43H29.54V21H.92Z" fill="#00ddae"/>
      <path d="M59.54,0H30a.47.47,0,0,0-.46.46V23.8a.46.46,0,0,0,.46.46H59.54A.46.46,0,0,0,60,23.8V.46A.47.47,0,0,0,59.54,0Zm-.46,23.34H30.46V21.83H59.08ZM30.46.92H59.08V2.43H30.46Zm0,2.43H59.08V21H30.46Z" fill="#00ddae"/>
      <path d="M30,23.43H.46A.47.47,0,0,0,0,23.9V47.23a.47.47,0,0,0,.46.47H30a.47.47,0,0,0,.46-.47V23.9A.47.47,0,0,0,30,23.43Zm-.46,23.34H.92V45.26H29.54ZM.92,24.36H29.54v1.51H.92Zm0,2.43H29.54V44.4H.92Z" fill="#00ddae"/>
      <path d="M59.54,23.43H30a.47.47,0,0,0-.46.47V47.23a.47.47,0,0,0,.46.47H59.54a.47.47,0,0,0,.46-.47V23.9A.47.47,0,0,0,59.54,23.43Zm-.46,23.34H30.46V45.26H59.08ZM30.46,24.36H59.08v1.51H30.46Zm0,2.43H59.08V44.4H30.46Z" fill="#00ddae"/>
    </svg>
  );
};

const Kiosks = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60px" height="60px" viewBox="0 0 37.04 60">
      <path d="M13.85,0c4.24,2.44,8.51,4.84,12.71,7.35a22.79,22.79,0,0,1,3.12,2.54c.48.41.31,1,.13,1.46-1.22,3.45-2.32,6.94-3.7,10.32A24.35,24.35,0,0,0,24.25,32.4a43.39,43.39,0,0,1,0,5.1,1,1,0,0,0,.61,1c3.74,2.14,7.46,4.31,11.21,6.44A1.64,1.64,0,0,1,37,46.65c0,.9,0,1.81,0,2.71a1.44,1.44,0,0,1-.81,1.44L20.38,59.92a.34.34,0,0,0-.09.08h-.38l-.14-.13Q10.32,54.43.86,49A1.5,1.5,0,0,1,0,47.52c0-.94,0-1.89,0-2.83a1.48,1.48,0,0,1,.85-1.48c1.59-.89,3.15-1.85,4.76-2.72a1.41,1.41,0,0,0,.83-1.43c0-7.89,0-11.17,0-19a4.18,4.18,0,0,1,.19-1.36c2-5.82,4-11.63,6-17.44A1.68,1.68,0,0,1,13.72,0Zm.44,2.52c-.11.26-.2.47-.27.68C12.19,8.48,10.35,13.75,8.54,19a3.6,3.6,0,0,0-.2,1.17c0,9.12,0,13.64,0,22.76A1.09,1.09,0,0,0,9,44.06c3.14,1.79,6.26,3.61,9.4,5.41.21.12.43.22.76.39,0-.45.05-.77.05-1.1,0-8.61,0-12.63,0-21.24a6.13,6.13,0,0,1,.31-1.85q2.74-8,5.57-16.06c.2-.59.13-.88-.43-1.19C21.39,6.59,18.17,4.71,15,2.86,14.76,2.74,14.55,2.65,14.29,2.52ZM3,44.22c.28.19.41.3.55.38q8.06,4.65,16.1,9.31a.92.92,0,0,0,1-.06L33.4,46.5c.19-.11.36-.25.64-.44l-9.76-5.63v.93c0,2.67,0,5.34,0,8a1.44,1.44,0,0,1-.82,1.43c-.89.48-1.76,1-2.62,1.51a1.35,1.35,0,0,1-1.48,0q-6.1-3.54-12.23-7.06A1.26,1.26,0,0,1,6.42,44c0-.51,0-1,0-1.72Zm18.16,5.56c1.12-.38,1.19-.46,1.19-1.52,0-8.47,0-12.34,0-20.81a5.16,5.16,0,0,1,.28-1.66c1.66-4.74,3.35-9.46,5-14.2.28-.78.12-1.08-.75-1.35-.08.19-.16.39-.23.58L21.29,26.36a4.35,4.35,0,0,0-.21,1.29c0,8.65,0,12.68,0,21.33A7.64,7.64,0,0,0,21.15,49.78ZM2.07,46.05c-.27,1.09-.2,1.27.64,1.75l15.66,9c.21.12.43.22.65.33.3-1,.21-1.26-.51-1.68L2.68,46.35C2.49,46.24,2.29,46.16,2.07,46.05ZM21.22,57.17c.21-.1.41-.2.6-.31l12.59-7.27c.74-.42.86-.81.51-1.69l-.66.35L21.82,55.42C21,55.89,20.91,56.15,21.22,57.17Z" fill="#00ddae"/>
      <path d="M9.16,19.77c.07-.26.14-.52.23-.78q2.37-6.87,4.76-13.74c.43-1.24.9-1.42,2-.78l7.09,4.1c.89.51,1,.82.67,1.81q-2.43,7-4.86,14c-.37,1.07-.88,1.25-1.87.69L9.93,20.91C9.49,20.65,9.12,20.36,9.16,19.77ZM17.45,23c.1-.22.18-.39.24-.56,1.37-3.92,2.72-7.85,4.1-11.77.17-.46,0-.67-.35-.9-1.71-1-3.39-1.94-5.09-2.91-.17-.1-.36-.17-.55-.26-.09.19-.16.29-.2.41q-2.07,6-4.15,12c-.14.4,0,.6.33.8,1.59.9,3.16,1.82,4.75,2.73C16.81,22.64,17.11,22.79,17.45,23Z" fill="#00ddae"/>
    </svg>
  );
};

export {Cpus, Screens, Videowalls, Kiosks};