import React from 'react';

const Fb = ({iconColor}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.55 22.71">
      <path fill={iconColor} fillRule="evenodd" d="M2.33,11.45v11a.27.27,0,0,0,.28.28H6.69A.28.28,0,0,0,7,22.43V11.27H9.93a.3.3,0,0,0,.29-.26l.28-3.37a.29.29,0,0,0-.28-.31H7V5a1,1,0,0,1,1-1h2.28a.28.28,0,0,0,.28-.28V.29A.29.29,0,0,0,10.27,0H6.42A4.09,4.09,0,0,0,2.33,4.09V7.33h-2A.29.29,0,0,0,0,7.62V11a.29.29,0,0,0,.29.29h2Z"/>
    </svg>
  );
};

export default Fb;