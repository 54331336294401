import React from 'react';
import {colors} from '../../style/constants.js';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { GatsbyImage } from "gatsby-plugin-image";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    background: colors.LIGHT_GREY,
    '& .gatsby-image-wrapper': {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    background: colors.WHITE,
    width: '100%',
    height: 240,
  }
}));

const Awards = (props) => {

  const classes = useStyles();
  return (
    <section className={classes.wrapper + ' clipped'}>
      <Container>
        <Grid container item alignItems='center' justifyContent='space-between' xs={12}> 
          <div className={classes.background + ' clipped'}/>
          <GatsbyImage image={props.awards.edges[0].node.list.gatsbyImageData} alt=""/>
        </Grid>
      </Container>
    </section>
  )
}

export default Awards;