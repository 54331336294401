import React from 'react';

const Ig = ({iconColor}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.69 23.69">
      <defs>
      <radialGradient id="igGradient" r="150%" cx="30%" cy="107%">
        <stop stopColor="#fdf497" offset="0" />
        <stop stopColor="#fdf497" offset="0.05" />
        <stop stopColor="#fd5949" offset="0.45" />
        <stop stopColor="#d6249f" offset="0.6" />
        <stop stopColor="#285AEB" offset="0.9" />
      </radialGradient>
      </defs>
      <path fill={iconColor} d="M18.08,4.36a1.4,1.4,0,1,0,1.39,1.4A1.4,1.4,0,0,0,18.08,4.36ZM11.89,6.15A5.85,5.85,0,1,0,17.74,12,5.85,5.85,0,0,0,11.89,6.15Zm0,9.6A3.75,3.75,0,1,1,15.64,12,3.75,3.75,0,0,1,11.89,15.75Zm11.8-8.61A7.14,7.14,0,0,0,16.56,0H7.14A7.14,7.14,0,0,0,0,7.14v9.42a7.14,7.14,0,0,0,7.14,7.13h9.42a7.13,7.13,0,0,0,7.13-7.13Zm-2.23,9.42a4.91,4.91,0,0,1-4.9,4.9H7.14a4.9,4.9,0,0,1-4.9-4.9V7.14a4.9,4.9,0,0,1,4.9-4.9h9.42a4.9,4.9,0,0,1,4.9,4.9Z"/>
    </svg>
  );
};

export default Ig;