import React from 'react';
import {colors} from '../../style/constants'

const User = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
      <path d="M0,0H26V26H0Z" fill="none"/>
      <path d="M14,6.375A2.625,2.625,0,1,1,11.375,9,2.625,2.625,0,0,1,14,6.375m0,11.25c3.713,0,7.625,1.825,7.625,2.625v1.375H6.375V20.25c0-.8,3.913-2.625,7.625-2.625M14,4a5,5,0,1,0,5,5A5,5,0,0,0,14,4Zm0,11.25c-3.337,0-10,1.675-10,5v2.5A1.254,1.254,0,0,0,5.25,24h17.5A1.254,1.254,0,0,0,24,22.75v-2.5C24,16.925,17.337,15.25,14,15.25Z" transform="translate(-1 -1)" fill={colors.LIGHT_BLUE}/>
    </svg>
  )
}

export default User;

