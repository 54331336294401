import React from "react";

const Magnet = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96">
      <defs>
        <linearGradient
          id="Dégradé_sans_nom_6"
          x1="71.95"
          x2="49.51"
          y1="79.34"
          y2="56.9"
          data-name="Dégradé sans nom 6"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#00ddae"></stop>
          <stop offset="1" stopColor="#57baa0"></stop>
        </linearGradient>
      </defs>
      <circle cx="48" cy="48" r="48" fill="#00ddae"></circle>
      <path
        fill="url(#Dégradé_sans_nom_6)"
        d="M39.6 95.19L20.67 76.26l13.87-1 .46-33.9 7.57-7.63 10.92-2.25 9.87 11.84v32h9.6l1.6-33.58-5-13 26 26A48.13 48.13 0 0147.91 95.9a49.48 49.48 0 01-8.31-.71z"
      ></path>
      <path
        fill="#fff"
        d="M48 18.82a27.27 27.27 0 00-27.24 27.24v30.36h14.79V64.71h24.9v11.71h14.79V46.06A27.27 27.27 0 0048 18.82zm0 3.52a23.74 23.74 0 0123.71 23.72v15.13H64V46.06a16 16 0 10-32 0v15.13h-7.71V46.06A23.74 23.74 0 0148 22.34zM32 72.9h-7.71v-8.19H32zm3.53-11.71V46.06a12.45 12.45 0 1124.9 0v15.13zM64 72.9v-8.19h7.73v8.19z"
      ></path>
    </svg>
  );
}

export default Magnet;
