import React from "react";
import {Cpus, Screens, Videowalls, Kiosks} from '../../components/svgs/hardware'; 

const hardwareFr = [
  {
    name: 'Ordinateurs de diffusion',
    picto: <Cpus />,
    link: '/equipement#players'
  },
  {
    name: 'Écrans intelligents',
    picto: <Screens />,
    link: '/equipement#screens'
  },
  {
    name: 'Murs vidéo',
    picto: <Videowalls />,
    link: '/equipement#videowalls-and-touchscreens'
  },
  {
    name: 'Bornes tactiles',
    picto: <Kiosks />,
    link: '/equipement#videowalls-and-touchscreens'
  },
];

const hardwareEn = [
  {
    name: 'Signage players',
    picto: <Cpus />,
    link: '/en/hardware#players'
  },
  {
    name: 'Smart screens',
    picto: <Screens />,
    link: '/en/hardware#screens'
  },
  {
    name: 'Videowalls',
    picto: <Videowalls />,
    link: '/en/hardware#videowalls-and-touchscreens'
  },
  {
    name: 'Touch screens',
    picto: <Kiosks />,
    link: '/en/hardware#videowalls-and-touchscreens'
  },
];

export {hardwareFr, hardwareEn};
